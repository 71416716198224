<template>
  <v-card color="white" rounded="lg" outlined>
    <block v-if="loading"></block>
    <v-card-text class="pa-2">
      <v-alert class="lighten-2" color="grey">
        <div class="text-h6">APERTURA DE OPERACIONES DE MES</div>
        <p>Este proceso abre las operaciones de un mes.</p>
        <p>
          Una vez aperturado el mes es posible crear, modificar o eliminar
          partidas.
        </p>
      </v-alert>
      <p class="text-body-1 text-center">
        Seleccione el mes que desea aperturar operaciones
      </p>

      <!-- form -->
      <v-form @submit.prevent="confirmOpen">
        <v-row justify="center">
          <v-col cols="12" sm="4" md="3" lg="2">
            <v-select
              v-model="form.month"
              label="Mes"
              :items="months"
              item-text="monthName"
              item-value="month"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="3" lg="2">
            <v-text-field
              v-model="form.year"
              label="Año"
              outlined
              type="number"
              attach
              dense
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pb-3" justify="center">
          <v-col cols="12" sm="6" md="5" lg="3">
            <v-btn type="submit" color="save" dark block>
              <div v-if="$vuetify.breakpoint.xs" class="d-flex flex-column">
                <span>Aperturar operaciones</span><br />
                <span>del mes seleccionado</span>
              </div>
              <template v-else>
                Aperturar operaciones del mes seleccionado
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-dialog v-model="showReopenDialog" max-width="800">
      <v-card>
        <v-card-title class="text-h6">
          Ingrese a continuación la contraseña de un usuario administrador.
        </v-card-title>
        <v-card-text>
          <v-col cols="12">
            <v-text-field
              v-model="form.pwd"
              label="Contraseña"
              outlined
              type="password"
              attach
              dense
              clearable
            ></v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn class="cancel" @click="() => {
            showReopenDialog = false;
            form.pwd = '';
          }">
              Cancelar
          </v-btn>
          <v-btn class="btn-add" @click="reopenMonthOperations">
              Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- confirm dialog -->
    <alert
      v-model="dialog"
      type="confirm"
      title="Confirmar la apertura de operaciones"
      :message="`¿Confirma la apertura de operaciones para el mes ${selectedMonth}?`"
      @action="openMonthOperations"
    ></alert>
    <alert
      v-model="dialog2"
      type="confirm-danger"
      title="Confirmar la apertura de operaciones"
      :message="`Hubo un problema al intentar aperturar las operaciones del mes de ${ selectedMonth }: ${ errorMessage }. Sin embargo puede reaperturar el mes seleccionado ingresando la contraseña de un usuario administrador ¿Desea Proceder?`"
      @action="enterPwdDialog"
    ></alert>
    <alert
      v-model="alert.show"
      v-bind="{ ...alert }"
    ></alert>
  </v-card>
</template>

<script>
export default {
  name: "OpenMonthPage",
  data: () => ({
    loading: false,
    dialog: false,
    form: {
      month: 1,
      year: new Date().getFullYear()
    },
    alert: {
      type: "success",
      show: false,
      title: "",
      body: "",
    },
    errorMessage: '',
    dialog2: false,
    showReopenDialog: false
  }),
  computed: {
    months() {
      const months = [];
      for (let i = 0; i < 12; i++) {
        let monthName = this.moment().month(i).format("MMMM");
        monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
        months.push({ month: i + 1, monthName});
      }
      return months;
    },
    selectedMonth( ) {
      return this.months.find( current => current.month === this.form.month ).monthName
    }
  },
  methods: {
    confirmOpen() {
      this.dialog = true;
    },
    enterPwdDialog() {
      this.dialog2 = false;
      this.showReopenDialog = true;
    },
    async reopenMonthOperations(){
      try {
        this.showReopenDialog = false;
  
        await this.$API.auth.specialAccess( { password: this.form.pwd } );
  
        await this.$API.accountingperiod.modifyAccountingPeriod( { month: this.form.month, year: this.form.year, is_closed: 0 } );
        
        this.showAlert(
          "success",
          `Se han Reperturado las Operaciones`,
          `Se reaperturaron las operaciones del mes de ${ this.selectedMonth }: `
        );
      } catch (error) {
        this.showAlert(
          "info",
          `Error al intentar Reaperturar las Operaciones del Mes`,
          `Hubo un problema al intentar reaperturar las operaciones del mes de ${ this.selectedMonth }: ` + error.message
        );
      }
    },
    async openMonthOperations() {
      this.loading = true;
      try {
        this.dialog = false;

        const { status, message } = await this.$API.accountingperiod.createAccountingPeriod( { month: this.form.month, year: this.form.year } );

        if( status === 400 ) {
          const { result } = await this.$API.accountingperiod.getAccountingPeriod( { month: this.form.month, year: this.form.year, is_closed: 0 } );

          if( result.length > 0 ) {
            this.showAlert(
              "info",
              `Error al intentar Aperturar las Operaciones del Mes`,
              `Hubo un problema al intentar aperturar las operaciones del mes de ${ this.selectedMonth }: ` + message
            );
          } else {
            this.errorMessage = message;
            this.dialog2 = true
          }
        } else {
          this.showAlert(
            "success",
            `Se han Aperturado las Operaciones`,
            `Se aperturaron las operaciones del mes de ${ this.selectedMonth }: `
          );
        }
      } catch (error) {
        this.showAlert(
          "info",
          `Error al intentar Aperturar las Operaciones del Mes`,
          `Hubo un problema al intentar aperturar las operaciones del mes de ${ this.selectedMonth }: ` + error.message
        );
      } finally { 
        this.loading = false;
      }
    },
    showAlert(type, title, message) {
      type = type == null ? "info" : type;
      this.alert.type = type;
      this.alert.title = title;
      this.alert.message = message;
      this.alert.show = true;
    },
  },
};
</script>

<style scoped>
  .cancel{
    background-color: #41464c !important;
    color: white;
  }
</style>
